import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='22'
    height='15'
    viewBox='0 0 22 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 4.84956C0 2.64249 1.78918 0.853302 3.99625 0.853302H18.0037C20.2108 0.853302 22 2.64249 22 4.84956V10.6173C22 12.8244 20.2108 14.6136 18.0037 14.6136H3.99626C1.78918 14.6136 0 12.8244 0 10.6173V4.84956ZM3.99625 2.25405C2.5628 2.25405 1.40075 3.4161 1.40075 4.84956V10.6173C1.40075 12.0508 2.5628 13.2129 3.99626 13.2129H18.0037C19.4372 13.2129 20.5992 12.0508 20.5992 10.6173V4.84956C20.5992 3.4161 19.4372 2.25405 18.0037 2.25405H3.99625Z'
      fill='#0E0E0E'
    />
    <path
      d='M11.0013 2.99691C10.6366 2.99691 10.3375 3.29011 10.3375 3.65609V4.00221C9.41234 4.28368 8.73537 5.13755 8.73537 6.1486C8.73537 7.3888 9.75363 8.39392 11.0013 8.39392C11.5205 8.39392 11.9397 8.81188 11.9397 9.32088C11.9397 9.82988 11.5205 10.2478 11.0013 10.2478C10.4821 10.2478 10.0629 9.82988 10.0629 9.32088C10.0629 8.95491 9.76377 8.6617 9.39912 8.6617C9.03448 8.6617 8.73537 8.95491 8.73537 9.32088C8.73537 10.3319 9.41234 11.1858 10.3375 11.4673V11.8134C10.3375 12.1794 10.6366 12.4726 11.0013 12.4726C11.3659 12.4726 11.665 12.1794 11.665 11.8134V11.4673C12.5902 11.1858 13.2672 10.3319 13.2672 9.32088C13.2672 8.08068 12.2489 7.07556 11.0013 7.07556C10.4821 7.07556 10.0629 6.6576 10.0629 6.1486C10.0629 5.6396 10.4821 5.22163 11.0013 5.22163C11.5205 5.22163 11.9397 5.6396 11.9397 6.1486C11.9397 6.51457 12.2388 6.80777 12.6035 6.80777C12.9681 6.80777 13.2672 6.51457 13.2672 6.1486C13.2672 5.13755 12.5902 4.28368 11.665 4.00221V3.65609C11.665 3.29011 11.3659 2.99691 11.0013 2.99691Z'
      fill='#0E0E0E'
    />
  </svg>
)

const SVGPaymentPaymentsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPaymentPaymentsIcon
