import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.6917 7.75998V12.4808C17.6917 15.3042 16.0783 16.5141 13.6583 16.5141H5.60082C5.18832 16.5141 4.79415 16.4775 4.42749 16.395C4.19832 16.3584 3.97833 16.2942 3.77666 16.2209C2.40166 15.7075 1.56749 14.5158 1.56749 12.4808V7.75998C1.56749 4.93665 3.18082 3.72668 5.60082 3.72668H13.6583C15.7117 3.72668 17.1875 4.59751 17.5817 6.58668C17.6458 6.95335 17.6917 7.32915 17.6917 7.75998Z'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.4427 10.5101V15.231C20.4427 18.0543 18.8293 19.2643 16.4093 19.2643H8.35182C7.67349 19.2643 7.05933 19.1726 6.52766 18.971C5.43683 18.5676 4.69432 17.7335 4.42849 16.3951C4.79516 16.4776 5.18932 16.5143 5.60182 16.5143H13.6593C16.0793 16.5143 17.6927 15.3043 17.6927 12.481V7.7601C17.6927 7.32926 17.656 6.94429 17.5827 6.58679C19.3243 6.95346 20.4427 8.18176 20.4427 10.5101Z'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.62357 12.5449C10.9601 12.5449 12.0436 11.4614 12.0436 10.1249C12.0436 8.78835 10.9601 7.70486 9.62357 7.70486C8.28704 7.70486 7.20356 8.78835 7.20356 10.1249C7.20356 11.4614 8.28704 12.5449 9.62357 12.5449Z'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.38166 8.10834V12.1417'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.8699 8.10861V12.142'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGPaymentDepositsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPaymentDepositsIcon
