import React from 'react'
import MyDefaultBreadcrumb from '../MyDefaultBreadcrumb'
import useDeviceCheck from '../../../utils/useDeviceCheck'

const MyDefaultHeaderBreadcrumb = ({ items }) => {
  const { isTabletPortraitOrMobile } = useDeviceCheck()
  const headerTitleItems =
    isTabletPortraitOrMobile && Array.isArray(items) ? items.slice(-1) : items

  return <MyDefaultBreadcrumb items={headerTitleItems} />
}

export default MyDefaultHeaderBreadcrumb
