import React, { useEffect } from 'react'
import { MixpanelProvider } from 'react-mixpanel-browser'
import userflow from 'userflow.js'
import { ToastContainer } from 'react-toastify'
import { withProfiler } from '@sentry/react'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { isMobile, isIOS } from 'react-device-detect'
import { SnackbarProvider } from 'notistack'
import authActions from './store/modules/authActions'
import routes from './routes'
import UpdateApp from './components/api/UpdateApp'
import userActions from './store/modules/userActions'
import { redirect } from './utils/UrlHelper'
import { useSocket } from './utils/socket'
import { getUserRole, moveToLoginPage } from './utils'
import { organizationChangingNavigate } from './layout/MainHeader/headerUtils'
import {
  isMobileAppSelector,
  lastActionTimeSelector,
  locationSelector,
  myMessagesInboxUnreadMessagesSelector,
  organizationSelector,
  selfActionTimeSelector,
  userSelector,
} from './store/selectors/selectors'
import MyDefaultModalTermsAndConditions from './components/MyDefaultModal/MyDefaultModalTermsAndConditions/MyDefaultModalTermsAndConditions'
import MyDefaultModalUpdatePassword from './components/MyDefaultModal/MyDefaultModalUpdatePassword/MyDefaultModalUpdatePassword'

const App = () => {
  const unread = useSelector(myMessagesInboxUnreadMessagesSelector)
  const lastActionTime = useSelector(lastActionTimeSelector)
  const selfActionTime = useSelector(selfActionTimeSelector)
  const organization = useSelector(organizationSelector)
  const user = useSelector(userSelector)
  const isMobileApp = useSelector(isMobileAppSelector)
  const location = useSelector(locationSelector)

  const handleVisibility = async () => {
    if (document.visibilityState === 'visible') {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'))
      if (localStorageData) {
        const data = JSON.parse(localStorageData.authReducer)
        const accessToken = Object.keys(data.accessToken)
        if (accessToken.length !== 0) {
          if (!authActions.isLoggedIn()) {
            authActions.storeAuthData(
              data.user,
              data.accessToken,
              data.unreadMessages
            )
            if (
              data.user.isAdmin ||
              data.user.isManager ||
              (data.user.isSupportAgent &&
                data.user.accessibleOrganizations &&
                data.user.accessibleOrganizations.length > 1)
            ) {
              redirect('/dashboard')
            } else {
              redirect('/messages')
            }
          } else {
            if (authActions.getUserData()._id !== data.user?._id && !isMobile) {
              window.location.reload()
            }
          }
        } else if (accessToken.length === 0 && authActions.isLoggedIn()) {
          moveToLoginPage(redirect, true, { isAppLoggedOut: true })
        }
      }
    }
  }

  const messageFromWebView = async (e) => {
    if (window.location.pathname !== '/messages') {
      if (JSON.parse(e.data).type === 'notification') {
        redirect('/messages', { messageFromWebView: JSON.parse(e.data) })
      } else if (JSON.parse(e.data).type === 'REDIRECT_FROM_SETTINGS') {
        redirect('/profile/notification')
      }
    }
  }

  // Organization's user's events
  useSocket(
    `private-organization=${organization?._id}-user=${user?._id}`,
    'USER_MESSAGES_COUNTERS',
    (data) => {
      authActions.setUnreadMessages(data.unread_messages_counter)
      authActions.setMyMessagesInboxUnreadMessages(
        data.my_messages_inbox_unread_count
      )
      authActions.setUnassignedMessages(data.unassigned_messages_counter)
      authActions.setArchivedMessages(data.archived_messages_counter)
      authActions.setNeedsAttentionMessagesCount({
        mainInboxCount: data.main_inbox_needs_attention_count,
        myMessagesInbox: data.my_messages_inbox_needs_attention_count,
      })
      localStorage.setItem('unreadMessages', data.unread_messages_counter)
      localStorage.setItem('unassignedMessages', data.unassigned_messages_counter)
    },
    !!organization?._id && !!user?._id
  )

  // User's specific events
  const { onReconnected } = useSocket(
    `private-user=${user?._id}`,
    'ORGANIZATION_WAS_CHANGED',
    async (data) => {
      const organizationId = organization?._id ? organization?._id : ''
      if (organizationId !== data.user._organization_id) {
        authActions.setUserData(data.user)
        await authActions.refreshOrganization()
        authActions.setUnreadMessages(data.unread_messages_counter)
        authActions.setMyMessagesInboxUnreadMessages(
          data.my_messages_inbox_unread_count
        )
        authActions.setUnassignedMessages(data.unassigned_messages_counter)
        authActions.setArchivedMessages(data.archived_messages_counter)
        authActions.setNeedsAttentionMessagesCount({
          mainInboxCount: data.main_inbox_needs_attention_count,
          myMessagesInbox: data.my_messages_inbox_needs_attention_count,
        })
        if (isMobileApp) {
          redirect('/messages', { centerDrawerFalse: false })
        } else {
          organizationChangingNavigate(data.user.organization, redirect, false)
        }
      }
    },
    !!user?._id
  )

  useEffect(() => {
    const handleReconnection = () => {
      userActions.refreshUserFS()
    }

    onReconnected(handleReconnection)
    return () => {
      onReconnected(null)
    }
  }, [onReconnected])

  useSocket(
    `private-user=${user?._id}`,
    ['LOCATION_WAS_CHANGED', 'USER_LOG_OUT', 'USER_UPDATED'],
    (event, data) => {
      switch (event) {
        case 'LOCATION_WAS_CHANGED':
          const locationId = location?._id ? location?._id : ''
          if (locationId !== data.user._location_id)
            authActions.setUserData(data.user)
          break
        case 'USER_LOG_OUT':
          moveToLoginPage(redirect, true)
          break
        case 'USER_UPDATED':
          if (data?.user) {
            authActions.setUserData(data.user)
          }
          break

        default:
          break
      }
    },
    !!user?._id
  )

  // Organization's events
  useSocket(
    `private-organization=${organization?._id}`,
    ['UNASSIGNED_MESSAGES_COUNTER', 'UPDATE_ORGANIZATION'],
    (event, data) => {
      switch (event) {
        case 'UPDATE_ORGANIZATION':
          authActions.setOrganizationLocal(
            Object.assign(organization, {
              restrictions: data.organization.restrictions,
              superhumanConfig: data.organization.superhumanConfig,
            })
          )
          break
        case 'UNASSIGNED_MESSAGES_COUNTER':
          authActions.setUnassignedMessages(data.unassigned_messages_counter)
          break

        default:
          break
      }
    },
    !!organization?._id
  )

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibility)
    if (authActions.isLoggedIn()) {
      if (window.platform === 'android') {
        document.addEventListener('message', messageFromWebView)
      } else if (window.platform === 'ios') {
        window.addEventListener('message', messageFromWebView)
      }
    }
    if (window.platform) {
      authActions.setIsMobileApp(true)
    } else {
      authActions.setIsMobileApp(false)
    }
    if (isMobile || isIOS || window.platform) return
    const userConsent = Notification.permission
    if (userConsent === 'granted') return
    Notification.requestPermission()
    return () => {
      window.localStorage.removeItem('isExtensionUrl')
      if (window.platform === 'android') {
        document.removeEventListener('message', messageFromWebView)
      } else if (window.platform === 'ios') {
        window.removeEventListener('message', messageFromWebView)
      }
    }
  }, [])

  const adjustHeight = () => {
    const height = window.innerHeight // Get the full inner height excluding the browser's UI
    document.documentElement.style.setProperty('--app-height', `${height}px`)
  }

  useEffect(() => {
    window.addEventListener('resize', adjustHeight)
    window.addEventListener('load', adjustHeight)
    return () => {
      window.removeEventListener('resize', adjustHeight)
      window.removeEventListener('load', adjustHeight)
    }
  }, [])

  useEffect(() => {
    const serverTime = parseInt(lastActionTime)
    const selfTime = parseInt(selfActionTime)
    if (serverTime && selfTime && serverTime > selfTime) {
      authActions.setSelfActionTime(lastActionTime)
      userActions.refreshUserFS()
    }
  }, [lastActionTime, selfActionTime])

  if (Object.keys(user).length && process.env.REACT_APP_USERFLOW_TOKEN) {
    // if (!isMobileApp) {
    //   userflow.setResourceCenterLauncherHidden(true)
    // }
    userflow.identify(user?._id, {
      name: user ? user?.firstName : '',
      email: user ? user?.email : '',
      createdAt: user ? user?.createdAt : '',
      userType: user
        ? getUserRole(
            user.isSuperAdmin,
            user.isAdmin,
            user.isManager,
            user.isSupportAgent
          )
        : '',
      organization: user ? user?.organization?.name : '',
      device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
      organization_id: user?.organization?.id || '',
      language: authActions.getLanguage(),
    })
    userflow.group(
      user?.organization?.id || '',
      // These are the group's attributes
      {
        name: user?.organization?.name || '',
        organization_id: user?.organization?.id || '',
      }
    )
  }

  return (
    <SnackbarProvider
      className='notification-bur'
      style={{ zIndex: 1400000 }}
      maxSnack={5}
      TransitionProps={{ direction: 'down' }}
      autoHideDuration={2500}
      anchorOrigin={{
        vertical: 'top',
        horizontal: authActions.getLanguageRtl() ? 'left' : 'right',
      }}
      action={
        <div
          style={{
            cursor: 'pointer',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        />
      }
    >
      <MixpanelProvider token={process.env.REACT_APP_MIXPANEL_TOKEN} config={{}}>
        <Helmet>
          <title>
            {process.env.REACT_APP_TITLE}
            {unread ? ` (${unread})` : ''}
          </title>
        </Helmet>
        <ToastContainer
          position='top-right'
          autoClose={2500}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <BrowserRouter history={window.location}>{routes}</BrowserRouter>
        <UpdateApp />
        <MyDefaultModalTermsAndConditions />
        <MyDefaultModalUpdatePassword />
      </MixpanelProvider>
    </SnackbarProvider>
  )
}

export default withProfiler(App)
