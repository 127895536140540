import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.5833 12.7833V15.5333C15.5833 19.2 14.1167 20.6667 10.45 20.6667H6.96667C3.3 20.6667 1.83334 19.2 1.83334 15.5333V12.05C1.83334 8.38332 3.3 6.91666 6.96667 6.91666H9.71667'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.5833 12.7833H12.65C10.45 12.7833 9.71667 12.05 9.71667 9.84999V6.91666L15.5833 12.7833Z'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.6333 2.33334H14.3'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.41666 5.08334C6.41666 3.56168 7.645 2.33334 9.16666 2.33334H11.5683'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.1667 7.83334V13.5075C20.1667 14.9283 19.0117 16.0833 17.5908 16.0833'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.1667 7.83334H17.4167C15.3542 7.83334 14.6667 7.14584 14.6667 5.08334V2.33334L20.1667 7.83334Z'
      stroke='#0E0E0E'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGPaymentReportsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPaymentReportsIcon
