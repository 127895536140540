import React, { useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText } from '../../../lang'
import SVGPaymentPaymentsIcon from '../../../icons/SVG/SVGPaymentPaymentsIcon'
import SVGPaymentReportsIcon from '../../../icons/SVG/SVGPaymentReportsIcon'
import SVGPaymentDepositsIcon from '../../../icons/SVG/SVGPaymentDepositsIcon'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import MyDefaultInsidePagePanel from '../../../components/MyDefaultInsidePagePanelLayout/MyDefaultInsidePagePanel/MyDefaultInsidePagePanel'
import MyDefaultInsidePageLeftPanel from '../../../components/MyDefaultInsidePagePanelLayout/MyDefaultInsidePageLeftPanel/MyDefaultInsidePageLeftPanel'
import MyDefaultDropdown from '../../../components/MyDefaultDropdown/MyDefaultDropdown'
import { useLayout } from '../../../layout/LayoutProvider/LayoutProvider'
import { getPaymentPageTitle } from '../utils/payment.navigation.utils'

const baseUrl = '/payment'

const PaymentNavigation = ({ children }) => {
  const { setPageTitle } = useLayout()
  const { isTabletPortraitOrMobile } = useDeviceCheck()
  const location = useLocation()
  const navigate = useNavigate()

  const [current, setCurrent] = useState(location.pathname)

  useEffect(() => {
    if (location.pathname !== current) {
      setCurrent(location.pathname)
    }
    if (location.pathname.includes('/details')) {
      setCurrent(`${baseUrl}/completed_deposits`)
    }
    setPageTitle(getPaymentPageTitle(location.pathname))
  }, [location.pathname])

  const subMenuDeposits = useMemo(() => {
    return [
      {
        key: `${baseUrl}/scheduled_deposits`,
        label: getText('WORD_SCHEDULED_DEPOSITS'),
      },
      {
        key: `${baseUrl}/completed_deposits`,
        label: getText('WORD_COMPLETED_DEPOSITS'),
      },
    ]
  }, [])

  const RespSubMenuDeposits = () => {
    const items = [
      {
        key: `${baseUrl}/scheduled_deposits`,
        label: getText('WORD_SCHEDULED_DEPOSITS'),
        onClick: () => {
          navigate(`${baseUrl}/scheduled_deposits`)
          setCurrent(`${baseUrl}/scheduled_deposits`)
        },
      },
      {
        key: `${baseUrl}/completed_deposits`,
        label: getText('WORD_COMPLETED_DEPOSITS'),
        onClick: () => {
          navigate(`${baseUrl}/completed_deposits`)
          setCurrent(`${baseUrl}/completed_deposits`)
        },
      },
    ]

    return (
      <MyDefaultDropdown items={items} placement={'bottom'}>
        <SVGPaymentDepositsIcon />
      </MyDefaultDropdown>
    )
  }

  const menuItems = useMemo(() => {
    return [
      {
        key: `${baseUrl}/payments`,
        label: getText('WORD_PAYMENT'),
        icon: <SVGPaymentPaymentsIcon />,
      },
      isTabletPortraitOrMobile
        ? {
            icon: <RespSubMenuDeposits />,
            label: getText('WORD_DEPOSITS'),
            key: 'deposits',
            className: current.includes('deposits') ? 'active' : '',
          }
        : {
            key: 'deposits',
            label: getText('WORD_DEPOSITS'),
            icon: <SVGPaymentDepositsIcon />,
            children: subMenuDeposits,
          },
      {
        key: `${baseUrl}/reports`,
        label: getText('WORD_REPORTS'),
        icon: <SVGPaymentReportsIcon />,
      },
    ]
  }, [])

  return (
    <MyDefaultInsidePagePanel>
      <MyDefaultInsidePageLeftPanel
        onClick={(e) => {
          if (e.key === 'deposits') {
            return
          }
          setCurrent(e.key)
          navigate(e.key)
        }}
        selectedRoute={current}
        items={menuItems}
        forceSubMenuRender
        navigationMenuIsOnTop={isTabletPortraitOrMobile}
        defaultOpenKeys={['deposits']}
      />
      {children}
    </MyDefaultInsidePagePanel>
  )
}

export default PaymentNavigation
