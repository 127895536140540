import { getText } from '../../../lang'

export const getPaymentPageTitle = (pathname) => {
  let text = ''

  if (pathname.includes('/payment/payments')) {
    text = getText('WORD_PAYMENT')
  }
  if (pathname.includes('/payment/scheduled_deposits')) {
    text = getText('WORD_SCHEDULED_DEPOSITS')
  }
  if (pathname.includes('/payment/completed_deposits')) {
    text = getText('WORD_COMPLETED_DEPOSITS')
  }
  if (pathname.includes('/payment/reports')) {
    text = getText('WORD_REPORTS')
  }
  if (pathname.includes('details')) {
    text = [
      { title: getText('WORD_COMPLETED_DEPOSITS') },
      { title: getText('WORD_DETAILS') },
    ]
  }
  return text
}
