import React, { forwardRef } from 'react'
import { Flex } from 'antd'
import Div from '../Div/Div'
import MyDefaultPageHeader from '../MyDefaultPageHeader/MyDefaultPageHeader'
import MyDefaultPageHeaderAdvanced from '../MyDefaultPageHeader/MyDefaultPageHeaderAdvanced'
import './MyDefaultPageLayout.scss'

const MyDefaultPageLayout = forwardRef(
  (
    {
      children,
      customHeader,
      headerProps,
      disableHeader,
      isAdvanced,
      preHeader,
      centered,
    },
    ref
  ) => {
    return (
      <Div
        ref={ref}
        className={`my_default_page_layout ${centered ? 'center' : ''}`}
      >
        <Flex gap={20} vertical>
          {preHeader && preHeader}
          {Boolean(!disableHeader) &&
            (customHeader || isAdvanced ? (
              <MyDefaultPageHeaderAdvanced {...headerProps} />
            ) : (
              <MyDefaultPageHeader {...headerProps} />
            ))}
        </Flex>
        {children}
      </Div>
    )
  }
)

export default MyDefaultPageLayout
