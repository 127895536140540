import React from 'react'
import { Dropdown } from 'antd'
import './MyDefaultDropdown.scss'

const MyDefaultDropdown = ({
  items,
  disabled,
  trigger = 'click',
  overlayClassName,
  dropdownRender,
  children,
  onOpenChange,
  className,
  placement,
}) => {
  return (
    <Dropdown
      menu={{ items }}
      disabled={disabled}
      trigger={trigger}
      placement={placement}
      overlayClassName={overlayClassName}
      className={className}
      dropdownRender={dropdownRender}
      onOpenChange={onOpenChange}
    >
      {children}
    </Dropdown>
  )
}

export default MyDefaultDropdown
