import React from 'react'
import { Popover } from 'antd'

const MyDefaultPopover = ({
  arrow = true,
  fresh,
  title,
  trigger = 'click',
  visible,
  onVisibleChange,
  overlayClassName,
  placement,
  content,
  forceRender,
  destroyTooltipOnHide,
  triggerPopover,
  className,
}) => {
  return (
    <Popover
      fresh={fresh}
      title={title}
      trigger={trigger}
      open={visible}
      arrow={arrow}
      onOpenChange={onVisibleChange}
      overlayClassName={overlayClassName}
      placement={placement}
      content={content}
      forceRender={forceRender}
      destroyTooltipOnHide={destroyTooltipOnHide}
      className={`my_default_popover ${className || ''}`}
    >
      {triggerPopover}
    </Popover>
  )
}

export default MyDefaultPopover
