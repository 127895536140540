import React, { forwardRef } from 'react'
import Div from '../Div/Div'
import MyDefaultBlockTitle from './MyDefaultBlockTitle'
import './MyDefaultBlock.scss'

const MyDefaultBlock = forwardRef(
  (
    {
      children,
      enableMarginTop,
      title,
      additionalHeader,
      fullWidth,
      minWidth,
      maxWidth,
      disableMarginTitle,
      className,
      backgroundColor,
      fullHeight,
      position,
      top,
      onClick,
      cursor,
      height,
      fontSize,
    },
    ref
  ) => {
    return (
      <Div
        ref={ref}
        className={`default_block_wrapper ${enableMarginTop ? 'margin_top' : ''} ${className || ''}`}
        style={{
          width: fullWidth ? '100%' : 'none',
          height: fullHeight ? '100%' : height || 'auto',
          minWidth: minWidth || 'auto',
          maxWidth: maxWidth || '625',
          position: position,
          top: top,
          backgroundColor: backgroundColor,
          cursor,
        }}
        onClick={onClick}
      >
        {title && (
          <MyDefaultBlockTitle
            title={title}
            additionalHeader={additionalHeader}
            disableMargin={disableMarginTitle}
            fontSize={fontSize}
          />
        )}
        {children}
      </Div>
    )
  }
)

export default MyDefaultBlock
