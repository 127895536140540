import React from 'react'
import { Breadcrumb } from 'antd'
import SVGSelectArrow from '../../icons/SVG/SVGSelectArrow'
import './MyDefaultBreadcrumb.scss'

const MyDefaultBreadcrumb = ({ items }) => {
  return (
    <Breadcrumb
      className='my_default_breadcrumb'
      separator={
        <SVGSelectArrow
          style={{
            transform: 'rotate(-90deg)',
          }}
        />
      }
      items={
        typeof items === 'string'
          ? [
              {
                title: items,
              },
            ]
          : items
      }
    />
  )
}

export default MyDefaultBreadcrumb
