import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import organizationActions from '../../../store/modules/organizationActions'
import { getText } from '../../../lang'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { useLayout } from '../../../layout/LayoutProvider/LayoutProvider'

const OrganizationContext = createContext()

export const OrganizationProvider = ({ children, _id }) => {
  const { setPageTitle } = useLayout()

  const navigate = useNavigate()
  const location = useLocation()

  const [organization, setOrganization] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const organizationIsInState = location?.state?.organization

  useEffect(() => {
    const getOrganizationFromParamsId = async () => {
      if (_id) {
        setIsLoading(true)
        const result = await organizationActions.getSingle(_id)
        if (result.success) {
          setOrganization(result.data)
        } else {
          navigate('/organization/list')
        }
      }
    }
    if (organizationIsInState) {
      setOrganization(organizationIsInState)
    } else {
      getOrganizationFromParamsId()
    }
    setIsLoading(false)
  }, [_id])

  const updatePageTitle = (subPageTitle) => {
    setPageTitle([{ title: organization.name }, { title: getText(subPageTitle) }])
  }

  return (
    <OrganizationContext.Provider
      value={{
        selectedOrganization: organization,
        setSelectedOrganization: setOrganization,
        updatePageTitle,
      }}
    >
      {!isLoading && organization._id ? children : <LoadingSpinner />}
    </OrganizationContext.Provider>
  )
}

export const useOrganizationLayout = () => useContext(OrganizationContext)
