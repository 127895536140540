import React, { useCallback } from 'react'
import { DatePicker } from 'antd'
import localeSP from 'antd/es/date-picker/locale/eu_ES'
import localEN from 'antd/es/date-picker/locale/en_US'
import localeFR from 'antd/es/date-picker/locale/fr_FR'
import 'dayjs/locale'
import authActions from '../../store/modules/authActions'
import { getText } from '../../lang'
import SVGMyDefaultDatePickerIcon from '../../icons/SVG/SVGMyDefaultDatePickerIcon'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultDatePicker.scss'

const MyDefaultDatePicker = ({
  isForm,
  onChange,
  required,
  message = getText('WORD_PLEASE_SELECT_DATE'),
  disabled,
  label,
  name = 'datepicker',
  hasFeedback,
  className,
  formStyle,
  allowClear = true,
  popupStyle,
  style,
  size = 'middle',
  placeholder = 'YYYY-MM-DD',
  disabledDate = false,
  dropdownClassName,
  value,
  suffixIcon,
  inputReadOnly,
  panelRender,
  format,
  isLast,
  color = 'grey',
  minDate,
  maxDate,
}) => {
  const userLang = authActions.getLanguage()

  const rules = useCallback(() => {
    const rules = []
    if (required && !disabled) {
      rules.push({ required: required, message: message })
    }
    return rules
  }, [required, disabled])

  return isForm ? (
    <MyDefaultFormItem
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={rules()}
      style={formStyle}
      isLast={isLast}
    >
      <DatePicker
        allowClear={allowClear}
        onChange={onChange}
        className={`my_default_datepicker ${color} ${className || ''}`}
        popupStyle={popupStyle}
        format={format}
        suffixIcon={suffixIcon || <SVGMyDefaultDatePickerIcon />}
        locale={
          userLang === 'fr' ? localeFR : userLang === 'sp' ? localeSP : localEN
        }
        style={style}
        minDate={minDate}
        maxDate={maxDate}
        size={size}
        popupClassName={`my_default_datepicker_dropdown ${dropdownClassName || ''}`}
        disabledDate={disabledDate}
        disabled={disabled}
        placeholder={placeholder}
        message={message}
      />
    </MyDefaultFormItem>
  ) : (
    <DatePicker
      allowClear={allowClear}
      onChange={onChange}
      className={`my_default_datepicker ${color} ${className || ''}`}
      popupStyle={popupStyle}
      style={style}
      minDate={minDate}
      maxDate={maxDate}
      locale={userLang === 'fr' ? localeFR : userLang === 'sp' ? localeSP : localEN}
      value={value}
      suffixIcon={suffixIcon || <SVGMyDefaultDatePickerIcon />}
      size={size}
      format={format}
      inputReadOnly={inputReadOnly}
      panelRender={panelRender}
      placeholder={placeholder}
      popupClassName={`my_default_datepicker_dropdown ${dropdownClassName || ''}`}
      disabledDate={disabledDate}
      disabled={disabled}
    />
  )
}

export default MyDefaultDatePicker
