import React, { memo, useEffect, useMemo, useState } from 'react'
import { Layout } from 'antd'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import authActions from '../../store/modules/authActions'
import userActions from '../../store/modules/userActions'
import {
  hasManyOrganizationsSelector,
  isAdminSelector,
  isMobileAppSelector,
  isSuperAdminSelector,
  locationSelector,
  organizationSelector,
  userSelector,
} from '../../store/selectors/selectors'
import { getText } from '../../lang'
import { moveToLoginPage } from '../../utils'
import SVGDrawerOpenIcon from '../../icons/SVG/SVGDrawerOpenIcon'
import SVGDrawerMessages from '../../icons/SVG/SVGDrawerMessages'
import SVGDrawerProfileEdit from '../../icons/SVG/SVGDrawerProfileEdit'
import SVGDrawerLogOut from '../../icons/SVG/SVGDrawerLogOut'
import SVGDrawerManageLocationsPurple from '../../icons/SVG/SVGDrawerManageLocationsPurple'
import MatDropMenu from '../../components/MatDropMenu'
import MatSelect from '../../components/MatSelect'
import { useLayout } from '../LayoutProvider/LayoutProvider'
import useDeviceCheck from '../../utils/useDeviceCheck'
import SelectLocations from '../../components/api/SelectLocations'
import MyDefaultHeaderBreadcrumb from '../../components/MyDefaultBreadcrumb/MyDefaultHeaderBreadcrumb/MyDefaultHeaderBreadcrumb'
import SelectOrganization from '../../components/api/SelectOrganization'
import SiderToggle from './SiderToggle'
import { organizationChangingNavigate } from './headerUtils'
import './MainHeader.scss'

const MainHeader = ({ isExtension }) => {
  const { pageTitle } = useLayout()
  const isSuperAdmin = useSelector(isSuperAdminSelector)
  const user = useSelector(userSelector)
  const isAdmin = useSelector(isAdminSelector)
  const organization = useSelector(organizationSelector)
  const location = useSelector(locationSelector)
  const isMobileApp = useSelector(isMobileAppSelector)
  const hasManyOrganizations = useSelector(hasManyOrganizationsSelector)

  const { isTabletPortraitOrMobile } = useDeviceCheck()

  const [selectedMobilePage, setSelectedMobilePage] = useState('')

  const navigate = useNavigate()
  const path = useLocation()
  const theme = authActions.getAppTheme()

  useEffect(() => {
    const url = path.pathname
    let newSelectedMenu = url
    if (url.includes('profile')) {
      newSelectedMenu = 'profile'
    } else {
      newSelectedMenu = 'messages'
    }
    setSelectedMobilePage(newSelectedMenu)
  }, [path.pathname])

  const ProfileEdit = useMemo(() => {
    return (
      isMobileApp && (
        <MatDropMenu
          bigText
          options={[
            {
              name: getText('TITLE_MESSAGES'),
              value: 'messages',
              img: <SVGDrawerMessages />,
              selected: selectedMobilePage,
            },
            {
              name: getText('ACTION_PROFILE'),
              value: 'profile',
              img: <SVGDrawerProfileEdit />,
              selected: selectedMobilePage,
            },
            {
              name: getText('ACTION_LOGOUT'),
              value: 'logout',
              img: <SVGDrawerLogOut />,
              selected: selectedMobilePage,
            },
          ]}
          overlayStyle={{ width: '100px' }}
          showArrow={false}
          className={'header_mobile_sidebar'}
          overlayClassName={'header_mobile_sidebar_dropdown'}
          trigger={<SVGDrawerOpenIcon />}
          onChange={async (val) => {
            setSelectedMobilePage(val)
            setTimeout(() => {
              if (val === 'profile') {
                navigate('/profile/edit')
              } else if (val === 'logout') {
                moveToLoginPage(navigate)
              } else if (val === 'messages') {
                navigate('/messages' + path.search, {
                  state: { centerDrawerFalse: false },
                })
              }
            }, 150)
          }}
        />
      )
    )
  }, [isMobileApp, selectedMobilePage])

  const Title = useMemo(() => {
    return (
      // <Div className='mat_header_page_title'>
      <MyDefaultHeaderBreadcrumb items={pageTitle} />
      // </Div>
    )
    // return <div className='mat-header-page-title'>{pageTitle}</div>
  }, [pageTitle])

  const OrganizationSelectContent = useMemo(() => {
    return (
      <SelectOrganization
        showSearch={isSuperAdmin || hasManyOrganizations}
        primary
        dropdownClassName={`mat-header-dropdown-responsive-first`}
        mainSelectClassName={
          window.location.pathname.includes('/dashboard') && 'white'
        }
        disableOpen={
          !isTabletPortraitOrMobile &&
          !isExtension &&
          !(isSuperAdmin || hasManyOrganizations)
        }
        disableSuffix={!(isSuperAdmin || hasManyOrganizations)}
        dropdownStyle={
          isTabletPortraitOrMobile && !isExtension
            ? { minWidth: 240, maxWidth: 240 }
            : {}
        }
        onChangeOrganization={(organization) => {
          organizationChangingNavigate(organization, navigate, isMobileApp)
        }}
        onDropdownVisibleChange={(opened) => {
          const body = document.body
          if (opened) {
            body.classList.add('body_class')
          } else {
            body.classList.remove('body_class')
          }
        }}
      />
    )
  }, [
    isSuperAdmin,
    organization,
    isMobileApp,
    pageTitle,
    isExtension,
    hasManyOrganizations,
    isTabletPortraitOrMobile,
  ])

  const LocationSelectContent = useMemo(() => {
    return (
      <>
        {hasManyOrganizations ||
        userActions.getUserHasPermission(
          user,
          organization?.restrictions?.allowAccessToAllLocations
        ) ? (
          <SelectLocations
            primary
            dropdownClassName={`mat-header-dropdown-responsive-second 
              }`}
            dropdownStyle={
              isTabletPortraitOrMobile && !isExtension
                ? { minWidth: 240, maxWidth: 240, right: 30 }
                : {}
            }
          />
        ) : (
          <MatSelect
            icon={<SVGDrawerManageLocationsPurple />}
            options={[
              {
                label: location?.name,
                value: location?._id,
              },
            ]}
            primary
            dropdownClassName={`mat-header-dropdown-responsive-second 
              }`}
            dropdownStyle={
              isTabletPortraitOrMobile && !isExtension
                ? { minWidth: 240, maxWidth: 240, right: 30 }
                : {}
            }
            disableSuffix={true}
            value={location?._id}
            open={
              Boolean(isTabletPortraitOrMobile || isExtension) ? undefined : false
            }
          />
        )}
      </>
    )
  }, [
    isMobileApp,
    isAdmin,
    organization,
    location,
    isTabletPortraitOrMobile,
    hasManyOrganizations,
  ])

  const SideBarContent = useMemo(() => {
    return (
      !isExtension && <>{!isMobileApp ? <SiderToggle theme={theme} /> : undefined}</>
    )
  }, [isMobileApp, isExtension, theme])

  return (
    <Layout.Header className='mat-header'>
      {SideBarContent}
      {ProfileEdit}
      {Title}
      <div className={'mat-header-selects-wrapper'}>
        {OrganizationSelectContent}
        {LocationSelectContent}
      </div>
    </Layout.Header>
  )
}

export default memo(MainHeader)
