import React from 'react'
import Div from '../Div/Div'

const MyDefaultBlockTitle = ({
  title,
  additionalHeader,
  disableMargin,
  fontSize,
}) => {
  return (
    <Div
      className={'my_default_block_title'}
      style={{ marginBottom: disableMargin ? 0 : 20, fontSize: fontSize }}
    >
      {title}
      {additionalHeader && additionalHeader}
    </Div>
  )
}

export default MyDefaultBlockTitle
