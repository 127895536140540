import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import OuterLayout from './layout/OuterLayout/OuterLayout'
import { moveToLoginPage } from './utils'
import PrivateRoute, { URValidator } from './PrivateRoute'
import authActions from './store/modules/authActions'
import {
  AUTOMATION,
  EMAIL,
  INBOUND,
  INBOUND_OLD,
  MANUALLY,
  OUTBOUND,
  SMS,
} from './devOptions.js'

const Login = lazy(() => import('./views/profile/login/index.js'))
const LoginVerification = lazy(
  () => import('./views/profile/login/LoginVerification.js')
)
const LoginVerificationGetCode = lazy(
  () => import('./views/profile/login/LoginVerificationGetCode.js')
)
const PasswordRequest = lazy(
  () => import('./views/profile/login/PasswordRequest.js')
)
const PasswordReset = lazy(() => import('./views/profile/login/PasswordReset.js'))
const StripeReturn = lazy(() => import('./components/api/StripeReturn.js'))
const StripeAuth = lazy(() => import('./components/api/StripeAuth.js'))
const Dashboard = lazy(() => import('./views/dashboard/Dashboard.js'))
const MessageMain = lazy(() => import('./views/messages/main/MessageMain.js'))
const ProfileEdit = lazy(() => import('./views/profile/ProfileEdit.js'))
const ProfileChangePassword = lazy(
  () => import('./views/profile/ProfileChangePassword.js')
)
const ProfileNotification = lazy(
  () => import('./views/profile/ProfileNotification.js')
)
const BroadcastingList = lazy(
  () => import('./views/broadcast/BroadcastList/BroadcastList.js')
)
const CreateCampaign = lazy(
  () => import('./views/broadcast/CreateCampaign/CreateCampaign.js')
)
const CreateByUpload = lazy(
  () => import('./views/broadcast/CreateCampaign/CreateByUpload/CreateByUpload.js')
)
const CreateSegment = lazy(
  () => import('./views/broadcast/CreateCampaign/CreateSegment/CreateSegment.js')
)
const StepPages = lazy(
  () =>
    import(
      './views/broadcast/CreateCampaign/StepPages/StepPagesContent/StepPages.js'
    )
)
const Invitation = lazy(() => import('./views/invitation/index.js'))
const Leaderboard = lazy(() => import('./views/invitation/leaderboard/index.js'))
const Appointment = lazy(() => import('./views/appointment/index.js'))

const Payments = lazy(() => import('./views/payment/payments/Payments.js'))
const CompletedDeposits = lazy(
  () => import('./views/payment/deposits/completedDeposits/CompletedDeposits.js')
)
const CompletedDepositDetails = lazy(
  () =>
    import(
      './views/payment/deposits/completedDeposits/completedDepositDetails/CompletedDepositDetails.js'
    )
)
const Reports = lazy(() => import('./views/payment/reports/Reports.js'))
const ScheduledDeposits = lazy(
  () => import('./views/payment/deposits/scheduledDeposits/ScheduledDeposits.js')
)

const OrganizationsList = lazy(
  () => import('./views/organization/list/OrganizationsList.js')
)
const General = lazy(() => import('./views/organization/settings/general/index.js'))
const Tags = lazy(() => import('./views/organization/settings/tags/index.js'))
const DripSequence = lazy(
  () => import('./views/organization/settings/dripsequence/DripSequence.js')
)
const DripSequenceTemplate = lazy(
  () =>
    import(
      './views/organization/settings/dripsequence_template/DripSequenceTemplate.js'
    )
)
const DripSequenceEdit = lazy(
  () => import('./views/organization/settings/dripsequence/DripSequenceEdit.js')
)
const DripSequenceEditTemplate = lazy(
  () =>
    import(
      './views/organization/settings/dripsequence_template/DripSequenceEditTemplate.js'
    )
)
const Touchpoints = lazy(
  () => import('./views/organization/settings/touchpoints/index.js')
)
const VoiceAi = lazy(
  () => import('./views/organization/settings/voice_ai/VoiceAi.js')
)
const Restrictions = lazy(
  () => import('./views/organization/settings/restrictions/index.js')
)
const Templates = lazy(
  () => import('./views/organization/settings/templates/index.js')
)
const TemplateEdit = lazy(
  () => import('./views/organization/settings/templates/TemplateEdit.js')
)
const WorkflowList = lazy(
  () => import('./views/organization/settings/workflow/list/WorkflowList.js')
)
const WorkflowEdit = lazy(
  () => import('./views/organization/settings/workflow/edit/WorkflowEdit.js')
)
const VoiceAiEdit = lazy(
  () => import('./views/organization/settings/voice_ai/VoiceAiEdit.js')
)
const OpeningHours = lazy(
  () => import('./views/organization/settings/opening_hours/index.js')
)
const Superhuman = lazy(
  () => import('./views/organization/settings/superhuman/index.js')
)
const IPWhiteList = lazy(
  () => import('./views/organization/settings/ip_white_list/index.js')
)
const SettingsTransfer = lazy(
  () => import('./views/organization/settings/settings_transfer/index.js')
)
const CustomAskConsentMessages = lazy(
  () =>
    import(
      './views/organization/settings/customAskConsentMessages/CustomAskConsentMessages.js'
    )
)

const UsersList = lazy(() => import('./views/users/UsersList.js'))

const LocationList = lazy(() => import('./views/locations/list/index.js'))
const EditLocation = lazy(() => import('./views/locations/settings/edit/index.js'))
const LocationIntegration = lazy(
  () => import('./views/locations/settings/integration/index.js')
)
const LocationReviewInvite = lazy(
  () => import('./views/locations/settings/reviewInvite/index.js')
)
const CalendarInvite = lazy(
  () => import('./views/locations/settings/calendarInvite/index.js')
)
const TextMe = lazy(() => import('./views/locations/settings/textMe/index.js'))
const IntegratedCta = lazy(() => import('./views/locations/settings/cta/index.js'))
const Settings = lazy(
  () => import('./views/locations/settings/matadorConnect/settings/index.js')
)
const Appearance = lazy(
  () => import('./views/locations/settings/matadorConnect/appearance/index.js')
)
const Questions = lazy(
  () => import('./views/locations/settings/matadorConnect/questions/index.js')
)
const Greetings = lazy(
  () =>
    import(
      './views/locations/settings/matadorConnect/greetings/greetingConfiguration/index.js'
    )
)
const GreetingEdit = lazy(
  () =>
    import(
      './views/locations/settings/matadorConnect/greetings/greetingConfiguration/GreetingEdit.js'
    )
)
const ListStories = lazy(
  () => import('./views/locations/settings/matadorConnect/stories/index.js')
)
const AddEditStory = lazy(
  () => import('./views/locations/settings/matadorConnect/stories/AddEditStory.js')
)
const LocationsCoupon = lazy(
  () =>
    import('./views/locations/settings/promotions/mobileCoupon/LocationsCoupon.js')
)
const LocationsExitIntent = lazy(
  () =>
    import('./views/locations/settings/promotions/exitIntent/LocationsExitIntent.js')
)
const SubscriberGrowth = lazy(
  () => import('./views/locations/settings/subscriberGrowth/index.js')
)
const FlowDesignerScreen = lazy(
  () => import('./views/locations/settings/matadorConnect/flowDesigner/index.js')
)
const RedirectMagic = lazy(() => import('./views/redirects/RedirectMagic.js'))
const CreateConversation = lazy(
  () => import('./views/redirects/CreateConversation.js')
)
const ExtensionProfileInfo = lazy(
  () => import('./views/redirects/extensionProfileInfo/index.js')
)
const ShortLinkRedirect = lazy(
  () => import('./views/redirects/ShortLinkRedirect.js')
)
const WelcomeGreetingsView = lazy(
  () =>
    import(
      './views/locations/settings/matadorConnect/greetings/welcomeGreetings/WelcomeGreetingsView.js'
    )
)
const AddEditWelcomeGreeting = lazy(
  () =>
    import(
      './views/locations/settings/matadorConnect/greetings/welcomeGreetings/AddEditWelcomeGreeting'
    )
)
const ErrorBox = lazy(() => import('./components/ErrorBox/index.js'))

const Root = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const userData = authActions.getUserData()
    if (authActions.isLoggedIn()) {
      const redirectTo = localStorage.getItem('redirectAfterLogin')
      localStorage.removeItem('redirectAfterLogin')

      if (redirectTo && URValidator(redirectTo)) {
        navigate(redirectTo)
      } else {
        if (authActions.getIsMobileApp()) {
          navigate('/messages')
        } else if (
          userData?.isAdmin ||
          userData?.isManager ||
          (userData?.isSupportAgent &&
            userData?.accessibleOrganizations &&
            userData?.accessibleOrganizations.length > 1)
        ) {
          navigate('/dashboard')
        } else {
          navigate('/messages')
        }
      }
    } else {
      moveToLoginPage(navigate)
    }
  }, [])

  return null
}

const routes = (
  <Routes>
    <Route path='/login' exact element={<OuterLayout>{<Login />}</OuterLayout>} />
    <Route
      path='/login_maglink'
      element={<OuterLayout>{<Login magicLink />}</OuterLayout>}
    />
    <Route
      path='/login/verification'
      element={<OuterLayout>{<LoginVerification />}</OuterLayout>}
    />
    <Route
      path='/login/verification'
      element={<OuterLayout>{<LoginVerification />}</OuterLayout>}
    />
    <Route
      path='/login/verification/code'
      element={<OuterLayout>{<LoginVerificationGetCode />}</OuterLayout>}
    />
    <Route
      path='/password_request'
      exact
      element={<OuterLayout>{<PasswordRequest />}</OuterLayout>}
    />
    <Route
      path='/reset'
      exact
      element={<OuterLayout>{<PasswordReset />}</OuterLayout>}
    />
    <Route
      path='/stripe/return/:id'
      element={
        <Suspense fallback={<></>}>
          <StripeReturn />
        </Suspense>
      }
    />
    <Route
      path='/stripe/reauth/:id'
      element={
        <Suspense fallback={<></>}>
          <StripeAuth />
        </Suspense>
      }
    />
    <Route
      path='/dashboard'
      element={<PrivateRoute noFooter>{<Dashboard />}</PrivateRoute>}
    />
    <Route
      exact
      path='/messages/extension'
      element={
        <PrivateRoute nofooter isExtension>
          <MessageMain isExtension />
        </PrivateRoute>
      }
    />
    <Route
      path={'/messages'}
      element={
        <PrivateRoute noFooter>
          <MessageMain />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path='/profile/edit'
      element={
        <PrivateRoute>
          <ProfileEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/profile/change-password'
      exact
      element={
        <PrivateRoute>
          <ProfileChangePassword />
        </PrivateRoute>
      }
    />
    <Route
      path='/profile/notification'
      exact
      element={
        <PrivateRoute>
          <ProfileNotification />
        </PrivateRoute>
      }
    />
    <Route
      path='/broadcasting/list'
      element={
        <PrivateRoute noFooter>
          <BroadcastingList createdBy={MANUALLY} />
        </PrivateRoute>
      }
    />
    <Route
      path='/automation/list'
      element={
        <PrivateRoute noFooter>
          <BroadcastingList createdBy={AUTOMATION} />
        </PrivateRoute>
      }
    />
    <Route
      path='/broadcasting/create-campaign'
      element={
        <PrivateRoute noFooter>
          <CreateCampaign />
        </PrivateRoute>
      }
    />
    <Route
      path='/broadcasting/create-campaign/file-upload'
      element={
        <PrivateRoute noFooter>
          <CreateByUpload />
        </PrivateRoute>
      }
    />
    <Route
      path='/broadcasting/create-campaign/create-segment'
      element={
        <PrivateRoute noFooter>
          <CreateSegment />
        </PrivateRoute>
      }
    />
    <Route
      path='/broadcasting/create-campaign/sub-pages'
      element={
        <PrivateRoute noFooter>
          <StepPages />
        </PrivateRoute>
      }
    />

    <Route
      path='/invitation/list'
      element={
        <PrivateRoute noFooter>
          <Invitation />
        </PrivateRoute>
      }
    />
    <Route
      path='/leaderboard'
      element={
        <PrivateRoute noFooter>
          <Leaderboard />
        </PrivateRoute>
      }
    />

    <Route
      path='/appointments'
      element={
        <PrivateRoute noFooter>
          <Appointment />
        </PrivateRoute>
      }
    />

    <Route
      path='/payment/payments'
      element={
        <PrivateRoute noFooter>
          <Payments />
        </PrivateRoute>
      }
    />
    <Route
      path='/payment/scheduled_deposits'
      element={
        <PrivateRoute noFooter>
          <ScheduledDeposits />
        </PrivateRoute>
      }
    />
    <Route
      path='/payment/completed_deposits'
      element={
        <PrivateRoute noFooter>
          <CompletedDeposits />
        </PrivateRoute>
      }
    />
    <Route
      path='/payment/completed_deposits/:_id/details'
      element={
        <PrivateRoute noFooter>
          <CompletedDepositDetails />
        </PrivateRoute>
      }
    />
    <Route
      path='/payment/reports'
      element={
        <PrivateRoute noFooter>
          <Reports />
        </PrivateRoute>
      }
    />

    <Route
      path='/organization/list'
      element={
        <PrivateRoute noFooter>
          <OrganizationsList />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/general'
      element={
        <PrivateRoute>
          <General />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/tags'
      element={
        <PrivateRoute noFooter>
          <Tags />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/list'
      element={
        <PrivateRoute noFooter>
          <DripSequence />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/template/list'
      element={
        <PrivateRoute noFooter>
          <DripSequenceTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/create'
      element={
        <PrivateRoute>
          <DripSequenceEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/edit'
      element={
        <PrivateRoute>
          <DripSequenceEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/template/create'
      element={
        <PrivateRoute>
          <DripSequenceEditTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/template/edit'
      element={
        <PrivateRoute>
          <DripSequenceEditTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/touchpoints'
      element={
        <PrivateRoute noFooter>
          <Touchpoints />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/inbound_old'
      element={
        <PrivateRoute noFooter>
          <VoiceAi type={INBOUND_OLD} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/inbound'
      element={
        <PrivateRoute noFooter>
          <VoiceAi type={INBOUND} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/outbound'
      element={
        <PrivateRoute noFooter>
          <VoiceAi type={OUTBOUND} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/restrictions'
      element={
        <PrivateRoute>
          <Restrictions />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/templates/sms/list'
      element={
        <PrivateRoute noFooter>
          <Templates templateType={SMS} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/templates/email/list'
      element={
        <PrivateRoute noFooter>
          <Templates templateType={EMAIL} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/template/sms/create'
      element={
        <PrivateRoute>
          <TemplateEdit templateType={SMS} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/template/email/create'
      element={
        <PrivateRoute>
          <TemplateEdit templateType={EMAIL} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/template/sms/edit'
      element={
        <PrivateRoute>
          <TemplateEdit templateType={SMS} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/template/email/edit'
      element={
        <PrivateRoute>
          <TemplateEdit templateType={EMAIL} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/workflow/list'
      element={
        <PrivateRoute noFooter>
          <WorkflowList />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/workflow/create'
      element={
        <PrivateRoute>
          <WorkflowEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/workflow/edit'
      element={
        <PrivateRoute>
          <WorkflowEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/inbound_old/create'
      element={
        <PrivateRoute>
          <VoiceAiEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/inbound_old/edit'
      element={
        <PrivateRoute>
          <VoiceAiEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/inbound/create'
      element={
        <PrivateRoute>
          <VoiceAiEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/inbound/edit'
      element={
        <PrivateRoute>
          <VoiceAiEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/outbound/create'
      element={
        <PrivateRoute>
          <VoiceAiEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/outbound/edit'
      element={
        <PrivateRoute>
          <VoiceAiEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/opening_hours'
      element={
        <PrivateRoute>
          <OpeningHours />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/superhuman'
      element={
        <PrivateRoute>
          <Superhuman />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/ip_whitelist'
      element={
        <PrivateRoute>
          <IPWhiteList />
        </PrivateRoute>
      }
    />

    <Route
      path='/organization/settings/:_id/settings_transfer'
      element={
        <PrivateRoute>
          <SettingsTransfer />
        </PrivateRoute>
      }
    />

    <Route
      path='/organization/settings/:_id/custom_ask_consent_messages'
      element={
        <PrivateRoute>
          <CustomAskConsentMessages />
        </PrivateRoute>
      }
    />

    <Route
      path='/users'
      element={
        <PrivateRoute noFooter>
          <UsersList />
        </PrivateRoute>
      }
    />

    <Route
      path='/location/list'
      element={
        <PrivateRoute noFooter>
          <LocationList />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/new'
      element={
        <PrivateRoute>
          <EditLocation />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/edit'
      element={
        <PrivateRoute>
          <EditLocation />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/integration'
      element={
        <PrivateRoute noFooter>
          <LocationIntegration />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/review'
      element={
        <PrivateRoute>
          <LocationReviewInvite />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/calendar'
      element={
        <PrivateRoute>
          <CalendarInvite />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/cta/mobile-cta'
      element={
        <PrivateRoute>
          <TextMe />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/cta/integrated-cta'
      element={
        <PrivateRoute>
          <IntegratedCta />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-main'
      element={
        <PrivateRoute>
          <Settings />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-appearance'
      element={
        <PrivateRoute>
          <Appearance />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-questions'
      element={
        <PrivateRoute noFooter>
          <Questions />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-greetings'
      element={
        <PrivateRoute>
          <Greetings />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-greetings/add'
      element={
        <PrivateRoute>
          <GreetingEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-greetings/edit'
      element={
        <PrivateRoute>
          <GreetingEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-stories'
      element={
        <PrivateRoute noFooter>
          <ListStories />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-stories/create'
      element={
        <PrivateRoute>
          <AddEditStory isNew />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-stories/edit'
      element={
        <PrivateRoute>
          <AddEditStory />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/promotions/mobileCoupon'
      element={
        <PrivateRoute>
          <LocationsCoupon />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/promotions/exitIntent'
      element={
        <PrivateRoute>
          <LocationsExitIntent />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/subsrciber-growth'
      element={
        <PrivateRoute>
          <SubscriberGrowth />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/flow-designer'
      element={
        <PrivateRoute>
          <FlowDesignerScreen />
        </PrivateRoute>
      }
    />
    <Route
      path='/magic'
      element={
        <Suspense fallback={<></>}>
          <RedirectMagic />
        </Suspense>
      }
    />
    <Route
      path='/create_conversation'
      element={
        <Suspense fallback={<></>}>
          <CreateConversation />
        </Suspense>
      }
    />
    <Route
      path='/profile-info'
      element={
        <OuterLayout isBlueExtension>
          <ExtensionProfileInfo />
        </OuterLayout>
      }
    />
    <Route
      path='/s/:_id'
      element={
        <Suspense fallback={<></>}>
          <ShortLinkRedirect />
        </Suspense>
      }
    />
    <Route path='/' element={<Root />} />
    <Route
      path='/location/settings/:_id/welcome-greetings'
      element={
        <PrivateRoute noFooter>
          <WelcomeGreetingsView />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/welcome-greetings/create'
      element={
        <PrivateRoute>
          <AddEditWelcomeGreeting isNew />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/welcome-greetings/edit'
      element={
        <PrivateRoute>
          <AddEditWelcomeGreeting />
        </PrivateRoute>
      }
    />
    <Route
      path={'*'}
      element={
        <Suspense fallback={<></>}>
          <ErrorBox />
        </Suspense>
      }
    />
  </Routes>
)

export default routes
